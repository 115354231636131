<template>
  <header class="header-global fixed-top">
    <base-nav class="navbar-main" transparent type
      style="background: linear-gradient(180deg, rgba(222,233,249,1) 60%, rgba(255,255,255,0) 130%);" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-4" to="/">
        <img src="/img/brand/optimized/Ayun_Logo_Full.svg" alt="Ayun! Logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="#">
            <img src="/img/brand/optimized/Ayun_Logo_Full.svg" alt="Ayun!" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <router-link slot="title" v-scroll-to="'/'" to="/" class="nav-link" data-toggle="dropdown" role="button">
          <i class="fa fa-home d-lg-none"></i>&nbsp;
          <span class="nav-link-inner--text">Home</span>
        </router-link>

        <router-link slot="title" v-scroll-to="'/#about-us'" to="/#about-us" class="nav-link" data-toggle="dropdown"
          role="button">
          <i class="fa fa-user d-lg-none"></i>&nbsp;
          <span class="nav-link-inner--text">About</span>
        </router-link>

        <router-link slot="title" to="/faqs" class="nav-link" data-toggle="dropdown" role="button">
          <i class="fa fa-question-circle d-lg-none"></i>&nbsp;
          <span class="nav-link-inner--text">FAQs</span>
        </router-link>

        <router-link slot="title" v-scroll-to="'#contact-us'" to="/#contact-us" class="nav-link" data-toggle="dropdown"
          role="button">
          <i class="fa fa-phone-square d-lg-none"></i>&nbsp;
          <span class="nav-link-inner--text">Contact Us</span>
        </router-link>
      </ul>
    </base-nav>
  </header>
</template>

<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
};
</script>

<style lang="scss" scoped>
.navbar-main .navbar-nav>li>a {
  color: #fff !important;
}

.navbar-transparent .navbar-nav .nav-link #header-links {
  color: #122C91 !important;
  font-family: 'Poppins' !important;
}

.navbar-transparent .navbar-nav .nav-link {
  color: #122C91 !important;
  font-family: 'Poppins' !important;

  &:hover {
    color: #F18F01 !important;
    font-family: 'Poppins' !important;
  }

  &.is-active {
    color: #F18F01 !important;
    font-weight: bold;
    font-family: 'Poppins' !important;
  }
}

.dropdown-item {
  cursor: pointer !important;
}

.btn {
  color: #122b91 !important;
  border-radius: 30px !important;
}
</style>
